import {NavLink} from 'react-router-dom';
import React from 'react';
import SearchSvg from '../../assets/search.svg';
import PropertiesSvg from '../../assets/properties.svg';
import RoomsSvg from '../../assets/rooms.svg';
import RatesSvg from '../../assets/rates.svg';
import SummarySvg from '../../assets/summary.svg';
import DetailsSvg from '../../assets/details.svg';
import './NavBar.scss';
import CONSTANTS from '../../helpers/constants';

const NavBar = () => {
  // TODO implement active class logic - cascading left to right
  return (
    <div className="menu">
      <div className="menu__item">
        <NavLink
          exact={true}
          activeClassName="is-active"
          to={CONSTANTS.APP_ROUTES.SEARCH}>
          <img src={SearchSvg} alt="Search" />
          <span>Search</span>
        </NavLink>
      </div>
      <div className="menu__item">
        <NavLink
          exact={true}
          activeClassName="is-active-not"
          to={CONSTANTS.APP_ROUTES.SEARCH}>
          <img src={PropertiesSvg} alt="Properties" />
          <span>Properties</span>
        </NavLink>
      </div>
      <div className="menu__item">
        <NavLink
          exact={true}
          activeClassName="is-active-not"
          to={CONSTANTS.APP_ROUTES.SEARCH}>
          <img src={RoomsSvg} alt="Rooms" />
          <span>Rooms</span>
        </NavLink>
      </div>
      <div className="menu__item">
        <NavLink
          exact={true}
          activeClassName="is-active-not"
          to={CONSTANTS.APP_ROUTES.SEARCH}>
          <img src={RatesSvg} alt="Rates" />
          <span>Rates</span>
        </NavLink>
      </div>
      <div className="menu__item">
        <NavLink
          exact={true}
          activeClassName="is-active-not"
          to={CONSTANTS.APP_ROUTES.SEARCH}>
          <img src={SummarySvg} alt="Booking Summary" />
          <span>Summary</span>
        </NavLink>
      </div>
      <div className="menu__item">
        <NavLink
          exact={true}
          activeClassName="is-active-not"
          to={CONSTANTS.APP_ROUTES.SEARCH}>
          <img src={DetailsSvg} alt="Booking Details" />
          <span>Details</span>
        </NavLink>
      </div>
    </div>
  );
};

export default NavBar;
