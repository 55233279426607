const API_URL = 'https://earthhotels.com/wp-admin/admin-ajax.php';
// const API_URL = 'http://localhost:9292/earthhotels/wp-admin/admin-ajax.php';
const API_ACTIONS = {
  DESCRIPTIVE_INFO: 'wsboom-missprop',
  AVAILABILITY: 'wsboom-avl',
  ADDONS: 'wsboom-addons',
};
const CHAIN = 'EHM';
const HOTEL_CODES = ['EHDEMO', 'EAR001'];

const APP_ROUTES = {
  PROPERTIES: '/properties',
  ROOMS: '/rooms',
  RATES: '/rates',
  SUMMARY: '/summary',
  DETAILS: '/details',
  SUCCESS: '/success',
  FAILURE: '/failure',
  SEARCH: '/',
};

const CONSTANTS = {
  API_URL,
  API_ACTIONS,
  CHAIN,
  HOTEL_CODES,
  APP_ROUTES,
};

export default CONSTANTS;
