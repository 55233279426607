import {
  RateActionTypes,
  RATES_FETCH_FAILURE,
  RATES_FETCH_REQUEST,
  RATES_FETCH_RESPONSE,
  RateState,
} from '../types/Rate';

const initialState: RateState = {
  rates: [],
  isFetchingRates: false,
  fetchingRatesFailed: false,
};

const rateReducer = (
  state = initialState,
  action: RateActionTypes,
): RateState => {
  switch (action.type) {
    case RATES_FETCH_REQUEST:
      return {
        ...state,
        rates: [],
        isFetchingRates: true,
        fetchingRatesFailed: false,
      };
    case RATES_FETCH_RESPONSE:
      return {
        ...state,
        rates: action.payload || [],
        isFetchingRates: false,
        fetchingRatesFailed: false,
      };
    case RATES_FETCH_FAILURE:
      return {
        ...state,
        rates: [],
        isFetchingRates: false,
        fetchingRatesFailed: true,
      };
    default:
      return state;
  }
};

export default rateReducer;
