import React, {ReactElement} from 'react';
import './Button.scss';

type ButtonProps = {
  text?: string;
  name?: string;
  id?: string;
  disabled?: boolean;
  icon?: ReactElement;
  loading?: boolean;
  onClick?: () => void;
  type: 'cta' | 'red' | 'alt-cta' | 'major' | 'red-outline' | 'red-disabled';
};

const Button = (props: ButtonProps) => {
  const {
    type,
    onClick = () => {},
    loading = false,
    disabled = false,
    icon = null,
  } = props;

  let finalClass = '';
  switch (type) {
    case 'alt-cta':
      finalClass = 'btn btn-light';
      break;
    case 'red':
      finalClass = 'btn btn-danger';
      break;
    case 'red-outline':
      finalClass = 'btn btn-outline-danger';
      break;
    case 'major':
      finalClass = 'btn btn-dark';
      break;
    case 'red-disabled':
      finalClass = 'btn btn-danger disabled';
      break;
    default:
      finalClass = 'btn btn-outline-dark';
      break;
  }
  return (
    <div className="ibe-text-button">
      <button
        type="button"
        disabled={loading || disabled}
        onClick={onClick}
        className={finalClass}>
        {icon}
        {!loading && props.text}
        {loading && (
          <div className="d-flex justify-content-center">
            <div
              className="spinner-border spinner-border-sm text-dark ms-3 me-3 mt-1 mb-1"
              role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </button>
    </div>
  );
};

export default Button;
