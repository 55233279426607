export const RATES_FETCH_REQUEST = 'RATES_FETCH_REQUEST';
export const RATES_FETCH_RESPONSE = 'RATES_FETCH_RESPONSE';
export const RATES_FETCH_FAILURE = 'RATES_FETCH_FAILURE';

export interface RateState {
  rates: Rate[];
  isFetchingRates: boolean;
  fetchingRatesFailed: boolean;
}

export interface Rate {
  code: string;
  roomCode: string;
  propertyCode: string;
  name: string;
  desc: string;
  guarantees: Guarantees;
  cancelPolicy: string;
  cancelDeadline: CancelDeadline[];
  cancelPenalty: CancelPenalty[];
  quantity: number;
  currency: string;
  net: number;
  tax: number;
  total: number;
  from: Date; // 2022-04-18
  to: Date; // 2022-04-18
  nights: Night[];
}

export interface Guarantees {
  GuaranteeDescription: string;
}

export interface CancelDeadline {
  AbsoluteDeadline?: string; // 1600
  OffsetTimeUnit?: string; // Day
  OffsetUnitMultiplier?: string; // 0
  OffsetDropTime?: string; // BeforeArrival
}

export interface CancelPenalty {
  calculation: string;
  amount: string;
}

export interface Night {
  date: Date; // 2022-04-18
  amount: string;
}

interface RatesGetRequestAction {
  type: typeof RATES_FETCH_REQUEST;
  payload: null;
}

interface RatesGetResponseAction {
  type: typeof RATES_FETCH_RESPONSE;
  payload: Rate[];
}

interface RatesGetFailureAction {
  type: typeof RATES_FETCH_FAILURE;
  payload: null;
}

export type RateActionTypes =
  | RatesGetRequestAction
  | RatesGetResponseAction
  | RatesGetFailureAction;
