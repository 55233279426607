import axios from 'axios';

import formatDate from 'date-fns/format';
// import AvailDrillJson from '../mock/GetAvailDrill.json';
import c from '../helpers/constants';

export function fetchPropertyAvailabilityAddons(
  from: Date,
  to: Date,
  propertyCode: string,
  roomCode: string,
  rateCode: string,
  adults: number,
  children: number,
) {
  const fromFormatted = formatDate(from, 'Y-m-d');
  const toFormatted = formatDate(to, 'Y-m-d');
  return (
    axios({
      url: `${c.API_URL}?
    action=${c.API_ACTIONS.ADDONS}&
    chain=${c.CHAIN}&
    from=${fromFormatted}&
    to=${toFormatted}&
    adults=${adults}&
    children=${children}&
    room=${roomCode}&
    rate=${rateCode}&
    hotels=${propertyCode}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response: any) => response?.data)
      // .then(() => AvailDrillJson)
      .catch((error: any) => {
        console.error(error);
      })
  );
}
