import {ThunkAction} from 'redux-thunk';
import {Action} from 'redux';
import {
  PROPERTIES_FETCH_FAILURE,
  PROPERTIES_FETCH_REQUEST,
  PROPERTIES_FETCH_RESPONSE,
  Property,
  PropertyActionTypes,
} from '../types/Property';

import {fetchPropertiesDescription} from '../api/PropertyApi';
import {RootState} from '../store/configureStore';

export function propertiesGetRequest(): PropertyActionTypes {
  return {
    type: PROPERTIES_FETCH_REQUEST,
    payload: null,
  };
}

export function propertiesGetResponse(
  properties: Property[],
): PropertyActionTypes {
  return {
    type: PROPERTIES_FETCH_RESPONSE,
    payload: properties,
  };
}

export function propertiesGetFailure(): PropertyActionTypes {
  return {
    type: PROPERTIES_FETCH_FAILURE,
    payload: null,
  };
}

export const propertiesGet =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(propertiesGetRequest());
    const asyncResp: any = await fetchPropertiesDescription();
    if (asyncResp?.success) {
      dispatch(propertiesGetResponse(asyncResp.data));
    } else {
      dispatch(propertiesGetFailure());
    }
  };
