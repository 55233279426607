import React from 'react';

import '../styles/Details.scss';

const Success = () => {
  return (
    <div className="details">
      <div className="details__title">BOOKING CONFIRMATION</div>

      <div className="details__hr" />

      <div className="details__title">INFORMATION</div>
    </div>
  );
};

export default Success;
