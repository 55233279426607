import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {useHistory} from 'react-router-dom';

import {
  getBookingProperty,
  getBookingRooms,
  getBookingSearch,
} from '../selectors/Booking';
import {getRates} from '../selectors/Rate';
import SelectInput from '../components/SelectInput';
import {Rate} from '../types/Rate';
import Button from '../components/Button';
import {Room} from '../types/Room';
import AdultSvg from '../assets/adult.svg';
import PlusSvg from '../assets/plus.svg';
import '../styles/Rooms.scss';
import {
  bookingRoomAdd,
  bookingRoomClear,
  bookingRoomEdit,
  bookingRoomsAndRatesClear,
} from '../actions/Booking';
import {BookingRoom} from '../types/Booking';
import Carousel from '../components/Carousel';
import Amenity from '../components/Amenity';
import CONSTANTS from '../helpers/constants';
import {addonsClear, addonsGet} from '../actions/Addon';

const Rooms = () => {
  const property = useSelector(getBookingProperty);
  const search = useSelector(getBookingSearch);
  const history = useHistory();
  const rates = useSelector(getRates);
  const bookingRooms = useSelector(getBookingRooms);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(bookingRoomsAndRatesClear());
  }, [dispatch]);

  const [valueMap, setValueMap] = useState(new Map());

  const getRatesForRoom = (roomCode: string) =>
    rates
      .filter((r: Rate) => r.roomCode === roomCode)
      ?.map((r: Rate) => ({
        value: r,
        label: `${r.name} at ${r.currency} ${r.net / r.nights.length}/Night`,
      }));

  const formatRate = (rate: Rate) =>
    `${rate.currency} ${rate.net / rate.nights.length}/Night`;

  const getRate = (room: Room) => {
    const rate = valueMap.get(room.code);
    if (!rate) {
      return '';
    }
    return formatRate(rate);
  };

  const roomHasBookings = (room: Room) => {
    const length =
      bookingRooms?.filter((r) => r?.room?.code === room?.code)?.length || 0;
    return length > 0;
  };

  const clearRate = (room: Room) => {
    setValueMap(new Map(valueMap).set(room.code, ''));
  };

  const onSelectRate = (rate: Rate, room: Room) => {
    setValueMap(new Map(valueMap).set(room.code, rate));
  };

  const onAddBookingRoom = (room: Room) => {
    const rate = valueMap.get(room.code);
    dispatch(bookingRoomAdd(room, rate));
    clearRate(room);
  };

  const onClearBookingRoom = (bookingRoom: BookingRoom) => {
    if (bookingRoom?.id) {
      dispatch(bookingRoomClear(bookingRoom?.id));
    }
  };

  const onNavigate = () => {
    if (search) {
      dispatch(addonsClear());
      bookingRooms?.forEach((bRoom) => {
        dispatch(bookingRoomEdit(bRoom.id, {...bRoom, addons: []}));
        dispatch(
          addonsGet(
            search.from,
            search.to,
            property?.propertyCode || '',
            bRoom.room.code,
            bRoom.rate.code,
            search.adults,
            search.children,
            bRoom.id,
          ),
        );
      });
    }
    history.push(CONSTANTS.APP_ROUTES.RATES);
  };

  return (
    <div className="rooms">
      {property?.rooms?.map((room: Room) => (
        <div className="row rooms__room" key={room.code}>
          <div className="col-lg-6">
            <Carousel room={room} />
            <Amenity amenities={room.amenities} />
          </div>
          <div className="col-lg-6">
            <div className="rooms__room__title">{room.name}</div>
            <div className="rooms__room__capacity">
              <img className="occupancy-icon" src={AdultSvg} alt="Occupancy" />
              Max {room.maxOccupancy} ppl
            </div>
            <div className="row">
              <div className="col-7">
                <SelectInput
                  id={`roomRatesDropDown-${room.code}`}
                  options={getRatesForRoom(room.code)}
                  value={getRate(room)}
                  onChange={(rate: Rate) => onSelectRate(rate, room)}
                  placeholder="Select Your Rate"
                />
              </div>
              <div className="col-5">
                <Button
                  disabled={!getRate(room)}
                  text="Add Room"
                  icon={<img src={PlusSvg} alt="plus-icon" />}
                  onClick={() => onAddBookingRoom(room)}
                  type="alt-cta"
                />
              </div>
            </div>
            {roomHasBookings(room) && (
              <div className="row">
                <div className="col-12">
                  {bookingRooms
                    ?.filter((r) => r.room.code === room.code)
                    .map((r) => (
                      <div key={r.id} onClick={() => onClearBookingRoom(r)}>
                        <div className="rooms__room__rate">
                          1 Room at {formatRate(r.rate)}{' '}
                          <i className="bi bi-x-lg" />
                        </div>
                      </div>
                    ))}
                  <div className="rooms__room__cta_container">
                    <Button
                      onClick={onNavigate}
                      type="red-outline"
                      text={`Customize Your Room${
                        bookingRooms && bookingRooms?.length > 1 ? 's' : ''
                      }`}
                    />
                  </div>
                </div>
              </div>
            )}
            {!roomHasBookings(room) && (
              <div className="row">
                <div className="col-12">
                  <div className="rooms__room__description">
                    {room?.description}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Rooms;
