import {RootState} from '../store/configureStore';

export const getBookingProperty = (state: RootState) =>
  state?.booking?.property;

export const getBookingRooms = (state: RootState) => state?.booking?.rooms;

export const getBookingDetails = (state: RootState) => state?.booking?.details;

export const getBookingSearch = (state: RootState) => state?.booking?.search;
