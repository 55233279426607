import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {useHistory} from 'react-router-dom';

import {getBookingRooms} from '../selectors/Booking';
import {getRates} from '../selectors/Rate';
import {Rate} from '../types/Rate';
import Button from '../components/Button';
import '../styles/Rates.scss';
import {BookingRoom} from '../types/Booking';
import Carousel from '../components/Carousel';
import Amenity from '../components/Amenity';
import CONSTANTS from '../helpers/constants';
import {bookingAddonsClear, bookingRoomEdit} from '../actions/Booking';
import {getAddons} from '../selectors/Addon';
import {Addon} from '../types/Addon';

const Rates = () => {
  const history = useHistory();
  const rates = useSelector(getRates);
  const addons = useSelector(getAddons);
  const dispatch = useDispatch();
  const bookingRooms = useSelector(getBookingRooms);

  useEffect(() => {
    dispatch(bookingAddonsClear());
  }, [dispatch]);

  const getRatesForRoom = (roomCode: string) =>
    rates.filter((r: Rate) => r.roomCode === roomCode);

  const getAddonsForBookingRoom = (bRoom: BookingRoom) =>
    addons?.filter((a) => a.bookingRoomId === bRoom.id);

  const rateHasAddons = (bRoom: BookingRoom) =>
    addons?.filter((a) => a.bookingRoomId === bRoom.id)?.length > 0;

  const formatRate = (rate: Rate) =>
    `${rate.currency} ${rate.net / rate.nights.length}`;

  const onEditBookingRoom = (room: BookingRoom, newRate: Rate) => {
    dispatch(bookingRoomEdit(room.id, {...room, rate: newRate}));
  };

  const onEditBookingRoomAddon = (room: BookingRoom, newAddon: Addon) => {
    const add = !isAddonSelected(room, newAddon);
    let finalAddons = room.addons || [];
    if (add) {
      finalAddons.push(newAddon);
    } else {
      finalAddons = finalAddons.filter((a) => a.id !== newAddon.id);
    }
    dispatch(bookingRoomEdit(room.id, {...room, addons: finalAddons}));
  };

  const isAddonSelected = (bRoom: BookingRoom, addon: Addon) =>
    bRoom.addons?.find((a) => a.id === addon.id);

  const onNavigate = () => {
    history.push(CONSTANTS.APP_ROUTES.SUMMARY);
  };

  console.log(addons);

  return (
    <div className="rates">
      {bookingRooms?.map((bRoom: BookingRoom) => (
        <div key={bRoom.id}>
          <div className="row rates__rate">
            <div className="col-lg-6">
              <Carousel room={bRoom.room} />
              <Amenity amenities={bRoom.room.amenities} />
            </div>
            <div className="col-lg-6">
              <div className="rates__rate__superTitle">You have selected</div>
              <div className="rates__rate__title mb-0">{bRoom.room.name}</div>
              <div className="row">
                <div className="col-12">
                  {getRatesForRoom(bRoom.room.code).map((r) => (
                    <div
                      key={`${bRoom.id}-${bRoom.room.code}-${r.code}`}
                      onClick={() => onEditBookingRoom(bRoom, r)}
                      className={`rates__rate__rate rates__rate__rate__ext ${
                        r.code === bRoom.rate.code ? 'selected' : ''
                      }`}>
                      <div>
                        <div className="rates__rate__rate__title">{r.name}</div>
                        <div className="rates__rate__rate__description">
                          {formatRate(r)}
                          <span>/Night</span>
                        </div>
                      </div>
                      <div>
                        {r.code === bRoom.rate.code ? (
                          <i className="bi bi-check-circle" />
                        ) : (
                          <div className="rates__rate__rate__select-cta">
                            Select
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="rates__addons">
            <div className="rates__addons__title-container">
              <div className="rates__addons__title">ADDONS</div>
              <div className="rates__addons__title-hr" />
            </div>
            <div className="rates__addons__container row">
              {!rateHasAddons(bRoom) && (
                <div>The selected rate doesn't have any add-ons</div>
              )}
              {getAddonsForBookingRoom(bRoom)?.map((a) => (
                <div
                  key={`${a.id}`}
                  className="rates__addons__addon col-md-3"
                  onClick={() => onEditBookingRoomAddon(bRoom, a)}>
                  <div
                    className={`rates__addons__addon__img-container ${
                      isAddonSelected(bRoom, a) ? 'addonSelected' : ''
                    }`}>
                    <img src="/yoga.svg" alt={`${a.code}`} />
                  </div>
                  <div className="rates__addons__addon__title">{a.name}</div>
                  <div className="rates__addons__addon__description">
                    {a.description}
                  </div>
                  <div className="rates__addons__addon__rate">
                    {bRoom.rate.currency} <b>{a.amountNet}</b>/
                  </div>
                  <div className="mt-3">
                    {isAddonSelected(bRoom, a) ? (
                      <Button text="Remove" type="alt-cta" />
                    ) : (
                      <Button text="Add" type="alt-cta" />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
      <div className="row mt-5">
        <div className="col-md-12">
          <Button type="cta" text="PROCEED" onClick={onNavigate} />
        </div>
      </div>
    </div>
  );
};

export default Rates;
