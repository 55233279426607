import {
  ADDONS_FETCH_FAILURE,
  ADDONS_FETCH_REQUEST,
  ADDONS_FETCH_RESPONSE,
  ADDONS_CLEAR,
  AddonsActionTypes,
  AddonState,
} from '../types/Addon';

const initialState: AddonState = {
  addons: [],
  isFetchingAddons: false,
  fetchingAddonsFailed: false,
};

const addonReducer = (
  state = initialState,
  action: AddonsActionTypes,
): AddonState => {
  switch (action.type) {
    case ADDONS_CLEAR:
      return {
        ...state,
        addons: [],
      };
    case ADDONS_FETCH_REQUEST:
      return {
        ...state,
        addons: [],
        isFetchingAddons: true,
        fetchingAddonsFailed: false,
      };
    case ADDONS_FETCH_RESPONSE:
      const final = [...state.addons, ...action.payload] || [];
      return {
        ...state,
        addons: final,
        isFetchingAddons: false,
        fetchingAddonsFailed: false,
      };
    case ADDONS_FETCH_FAILURE:
      return {
        ...state,
        addons: [],
        isFetchingAddons: false,
        fetchingAddonsFailed: true,
      };
    default:
      return state;
  }
};

export default addonReducer;
