import React from 'react';
import {Switch, Route} from 'react-router-dom';

import Details from '../../views/Details';
import Failure from '../../views/Failure';
import Search from '../../views/Search';
import Properties from '../../views/Properties';
import Rooms from '../../views/Rooms';
import Rates from '../../views/Rates';
import Summary from '../../views/Summary';
import Success from '../../views/Success';

const AppRouter = () => {
  return (
    <Switch>
      <Route path="/properties" component={Properties} />
      <Route path="/rooms" component={Rooms} />
      <Route path="/rates" component={Rates} />
      <Route path="/summary" component={Summary} />
      <Route path="/details" component={Details} />
      <Route path="/success" component={Success} />
      <Route path="/failure" component={Failure} />
      <Route path="/" component={Search} />
    </Switch>
  );
};

export default AppRouter;
