import React from 'react';
import {useSelector} from 'react-redux';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {useHistory} from 'react-router-dom';

import {getBookingRooms} from '../selectors/Booking';
import {Rate} from '../types/Rate';
import Button from '../components/Button';
import '../styles/Rates.scss';
import {BookingRoom} from '../types/Booking';
import Carousel from '../components/Carousel';
import Amenity from '../components/Amenity';
import CONSTANTS from '../helpers/constants';

const Summary = () => {
  const history = useHistory();
  const bookingRooms = useSelector(getBookingRooms);

  const formatRate = (rate: Rate) =>
    `${rate.currency} ${rate.net / rate.nights.length}`;

  const onNavigate = () => {
    history.push(CONSTANTS.APP_ROUTES.DETAILS);
  };

  return (
    <div className="rates">
      {bookingRooms?.map((bRoom: BookingRoom) => (
        <div key={bRoom.id}>
          <div className="row rates__rate">
            <div className="col-lg-6">
              <Carousel room={bRoom.room} />
              <Amenity amenities={bRoom.room.amenities} />
            </div>
            <div className="col-lg-6">
              <div className="rates__rate__superTitle">You have selected</div>
              <div className="rates__rate__title mb-0">{bRoom.room.name}</div>
              <div className="row">
                <div className="col-12">
                  <div className="rates__rate__rate rates__rate__rate__ext rates__rate__rate__ext__summary">
                    <div>
                      <div className="rates__rate__rate__title">
                        {bRoom.rate.name}
                      </div>
                      <div className="rates__rate__rate__description">
                        {formatRate(bRoom.rate)}
                        <span>/Night</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {bRoom.addons.length > 0 && (
                  <div className="summary__addons__title">ADD-ONS</div>
                )}
                {bRoom.addons.map((a) => (
                  <div key={`${a.id}`} className="summary__addons__addon">
                    <div className="summary__addons__addon__img-container">
                      <img src="/yoga.svg" alt={`${a.code}`} />
                    </div>
                    <div className="summary__addons__addon__text-container">
                      <div className="summary__addons__addon__title">
                        {a.name}
                      </div>
                      <div className="summary__addons__addon__description">
                        {a.description}
                      </div>
                    </div>
                    <div className="summary__addons__addon__rate">
                      {bRoom.rate.currency} <b>{a.amountNet}</b>/
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <textarea
                className="summary__notes"
                rows={5}
                placeholder="Notes for your stay"
              />
              <div className="summary__hr" />
            </div>
          </div>
        </div>
      ))}
      <div className="row mt-5">
        <div className="col-md-12">
          <Button type="cta" text="CHECKOUT" onClick={onNavigate} />
        </div>
      </div>
    </div>
  );
};

export default Summary;
