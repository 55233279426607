import axios from 'axios';

// import PropertyInfoMockJson from '../mock/GetHotelInfo.json';
import c from '../helpers/constants';

export function fetchPropertiesDescription() {
  return (
    axios({
      url: `${c.API_URL}?
    action=${c.API_ACTIONS.DESCRIPTIVE_INFO}&
    chain=${c.CHAIN}&
    hotels=${c.HOTEL_CODES.join(',')}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response: any) => response?.data)
      // .then(() => PropertyInfoMockJson)
      .catch((error: any) => {
        console.error(error);
      })
  );
}
