import {
  PROPERTIES_FETCH_REQUEST,
  PROPERTIES_FETCH_RESPONSE,
  PROPERTIES_FETCH_FAILURE,
  PropertyState,
  PropertyActionTypes,
} from '../types/Property';

const initialState: PropertyState = {
  properties: [],
  isFetchingProperties: false,
  fetchingPropertiesFailed: false,
};

const propertyReducer = (
  state = initialState,
  action: PropertyActionTypes,
): PropertyState => {
  switch (action.type) {
    case PROPERTIES_FETCH_REQUEST:
      return {
        ...state,
        properties: [],
        isFetchingProperties: true,
        fetchingPropertiesFailed: false,
      };
    case PROPERTIES_FETCH_RESPONSE:
      return {
        ...state,
        properties: action.payload || [],
        isFetchingProperties: false,
        fetchingPropertiesFailed: false,
      };
    case PROPERTIES_FETCH_FAILURE:
      return {
        ...state,
        properties: [],
        isFetchingProperties: false,
        fetchingPropertiesFailed: true,
      };
    default:
      return state;
  }
};

export default propertyReducer;
