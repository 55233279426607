import React, {useEffect, useRef, useState} from 'react';
import {Modal} from 'bootstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './Carousel.scss';
import {Room} from '../../types/Room';

type CarouselProps = {
  room: Room;
};

const Carousel = (props: CarouselProps) => {
  const myModalRef = useRef(null);
  const [modal, setModal] = useState(null);
  const [activeImgUri, setActiveImgUri] = useState('');

  const onOpenModal = (imgUri: string) => {
    setActiveImgUri(imgUri);
    // @ts-ignore
    modal.show();
  };
  useEffect(() => {
    // @ts-ignore
    setModal(new Modal(myModalRef.current));
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const renderModal = () => (
    <div
      className="modal fade"
      id="exampleModalToggle"
      aria-hidden="true"
      ref={myModalRef}
      aria-labelledby="exampleModalToggleLabel"
      tabIndex={-1}>
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content body-in-modal">
          <div className="modal-header header-in-modal">
            <h5 className="modal-title" id="exampleModalToggleLabel" />
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <div className="d-flex justify-content-center align-items-center flex-row flex-grow-1 flex-shrink-1 height-full">
              <img
                className="img-in-modal"
                src={activeImgUri}
                alt={activeImgUri}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const {room} = props;

  return (
    <div className="ibe-carousel">
      <div className="ibe-carousel__img__border" />
      {renderModal()}
      <div className="ibe-carousel__zoom-row">
        <div className="ibe-carousel__zoom-col">
          <Slider {...settings}>
            {room?.images.map((imgUri, i) => (
              <img
                onClick={() => onOpenModal(imgUri)}
                key={i}
                className="ibe-carousel__img"
                src={imgUri}
                alt={imgUri}
              />
            ))}
          </Slider>
        </div>
        <div className="my-col">
          <div className="ibe-carousel__img__zoom" />
        </div>
      </div>
    </div>
  );
};

export default Carousel;
