import {
  BOOKING_CLEAR_PROPERTY,
  BOOKING_CLEAR_SEARCH,
  BOOKING_SET_PROPERTY,
  BOOKING_SET_SEARCH,
  BOOKING_ADD_ROOM,
  BOOKING_EDIT_ROOM,
  BOOKING_CLEAR_ROOM,
  BookingState,
  BOOKING_CLEAR_ROOMS_AND_RATES,
  BOOKING_CLEAR_ADDONS,
} from '../types/Booking';

const initialState: BookingState = {
  details: null,
  property: null,
  rooms: [],
  search: null,
};

const bookingReducer = (state = initialState, action: any): BookingState => {
  switch (action.type) {
    case BOOKING_ADD_ROOM:
      return {
        ...state,
        rooms: [...(state.rooms || []), action.payload],
      };
    case BOOKING_EDIT_ROOM:
      const finalArrayEdit = [...(state.rooms || [])];
      const editIndex = state.rooms?.findIndex(
        (r) => r.id === action.payload.id,
      );
      if (editIndex !== undefined && editIndex >= 0) {
        finalArrayEdit.splice(editIndex, 1, action.payload.room);
      }
      return {
        ...state,
        rooms: finalArrayEdit,
      };
    case BOOKING_CLEAR_ROOMS_AND_RATES:
      return {
        ...state,
        rooms: [],
      };
    case BOOKING_CLEAR_ADDONS:
      return {
        ...state,
        rooms: state.rooms?.map((r) => ({...r, addons: []})) || [],
      };
    case BOOKING_CLEAR_ROOM:
      const finalArray = [...(state.rooms || [])];
      return {
        ...state,
        rooms: finalArray.filter((r) => r.id !== action.payload.id),
      };
    case BOOKING_SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case BOOKING_CLEAR_SEARCH:
      return {
        ...state,
        search: null,
      };
    case BOOKING_SET_PROPERTY:
      return {
        ...state,
        property: action.payload,
      };
    case BOOKING_CLEAR_PROPERTY:
      return {
        ...state,
        property: null,
      };
    default:
      return state;
  }
};

export default bookingReducer;
