import {ThunkAction} from 'redux-thunk';
import {Action} from 'redux';
import {v4 as uuidv4} from 'uuid';
import _ from 'lodash';
import {
  Addon,
  ADDONS_CLEAR,
  ADDONS_FETCH_FAILURE,
  ADDONS_FETCH_REQUEST,
  ADDONS_FETCH_RESPONSE,
  AddonsActionTypes,
} from '../types/Addon';

import {RootState} from '../store/configureStore';
import {fetchPropertyAvailabilityAddons} from '../api/AddonsApi';

export function addonsClearAction(): AddonsActionTypes {
  return {
    type: ADDONS_CLEAR,
    payload: null,
  };
}

export function addonsGetRequest(): AddonsActionTypes {
  return {
    type: ADDONS_FETCH_REQUEST,
    payload: null,
  };
}

export function addonsGetResponse(addons: Addon[]): AddonsActionTypes {
  return {
    type: ADDONS_FETCH_RESPONSE,
    payload: addons,
  };
}

export function addonsGetFailure(): AddonsActionTypes {
  return {
    type: ADDONS_FETCH_FAILURE,
    payload: null,
  };
}

export const addonsGet =
  (
    from: Date,
    to: Date,
    propertyCode: string,
    roomCode: string,
    rateCode: string,
    adults: number,
    children: number,
    bookingRoomId: string,
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(addonsGetRequest());
    const asyncResp: any = await fetchPropertyAvailabilityAddons(
      from,
      to,
      propertyCode,
      roomCode,
      rateCode,
      adults,
      children,
    );
    if (asyncResp.success && asyncResp.data) {
      const d: any = _.first(asyncResp.data) || {};
      const addons =
        d.services?.map((s: any) => ({
          ...s,
          roomCode,
          id: uuidv4(),
          rateCode,
          bookingRoomId,
        })) || [];
      dispatch(addonsGetResponse(addons));
    } else {
      dispatch(addonsGetFailure());
    }
  };

export const addonsClear =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(addonsClearAction());
  };
