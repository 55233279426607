import React from 'react';

import '../styles/Details.scss';
import TextInput from '../components/TextInput';
import Button from '../components/Button';
import CONSTANTS from '../helpers/constants';
import {useHistory} from 'react-router-dom';

const Details = () => {
  const history = useHistory();
  const onNavigate = () => {
    history.push(CONSTANTS.APP_ROUTES.SUCCESS);
  };
  return (
    <div className="details">
      <div className="row">
        <div className="col-12">
          <div className="details__title">PERSONAL DETAILS</div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <TextInput placeholder="First Name" />
        </div>
        <div className="col-md-6">
          <TextInput placeholder="Last Name" />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <TextInput placeholder="Email" />
        </div>
        <div className="col-md-6">
          <TextInput placeholder="Contact Number" />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <TextInput placeholder="Nationality" />
        </div>
        <div className="col-md-6">
          <input type="checkbox" />
          <label>&nbsp;Join our community</label>
        </div>
      </div>

      <div className="details__hr" />

      <div className="row">
        <div className="col-12">
          <div className="details__title">PAYMENT DETAILS</div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <TextInput placeholder="Cardholder Name" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <TextInput placeholder="Credit Card Number" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <TextInput placeholder="Expiry Date" />
        </div>
        <div className="col-md-6">
          <TextInput placeholder="CVV" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <input type="checkbox" />
          <label>&nbsp;I accept the terms and conditions</label>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-12">
          <Button type="cta" text="BOOK NOW" onClick={onNavigate} />
        </div>
      </div>
    </div>
  );
};

export default Details;
