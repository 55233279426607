import React from 'react';
import './TextInput.scss';

type TextInputProps = {
  placeholder?: string;
  name?: string;
  value?: string | number;
  id?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
};

const TextInput = (props: TextInputProps) => {
  const {
    id = '',
    value = '',
    name = '',
    placeholder = '',
    onChange = () => {},
    onBlur = () => {},
  } = props;
  return (
    <div className="ibe-text-input">
      <input
        id={id}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        value={`${value}`}
        name={name}
        type="text"
      />
    </div>
  );
};

export default TextInput;
