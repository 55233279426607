import {Room} from './Room';

export const PROPERTIES_FETCH_REQUEST = 'PROPERTIES_FETCH_REQUEST';
export const PROPERTIES_FETCH_RESPONSE = 'PROPERTIES_FETCH_RESPONSE';
export const PROPERTIES_FETCH_FAILURE = 'PROPERTIES_FETCH_FAILURE';

export interface Geolocation {
  latitude: number;
  longitude: number;
}

export interface PropertyService {
  description: string;
  code: string;
  existsCode: string;
  codeDetail: string;
}

export interface PropertyPhone {
  locationType: string;
  techType: string;
  useType: string;
  countryAccessCode: string;
  areaCityCode: string;
  number: string;
}

export interface Property {
  currencyCode: string;
  languageCode: string;
  name: string;
  chainCode: string;
  propertyCode: string;
  timezone: string;
  location: Geolocation;
  services: PropertyService[];
  rooms: Room[];
  images: string[];
  phones: PropertyPhone[];
  emails: string[];
  address: string;
  city: string;
  postcode: string;
  countryCode: string;
}

export interface PropertyState {
  properties: Property[];
  isFetchingProperties: boolean;
  fetchingPropertiesFailed: boolean;
}

interface PropertiesGetRequestAction {
  type: typeof PROPERTIES_FETCH_REQUEST;
  payload: null;
}

interface PropertiesGetResponseAction {
  type: typeof PROPERTIES_FETCH_RESPONSE;
  payload: Property[];
}

interface PropertiesGetFailureAction {
  type: typeof PROPERTIES_FETCH_FAILURE;
  payload: null;
}

export type PropertyActionTypes =
  | PropertiesGetRequestAction
  | PropertiesGetResponseAction
  | PropertiesGetFailureAction;
