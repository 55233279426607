import React from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import format from 'date-fns/format';
import differenceInDays from 'date-fns/differenceInDays';

import './SideBar.scss';
import {
  getBookingProperty,
  getBookingRooms,
  getBookingSearch,
} from '../../selectors/Booking';
import CONSTANTS from '../../helpers/constants';

const SideBar = () => {
  const search = useSelector(getBookingSearch);
  const property = useSelector(getBookingProperty);
  const rooms = useSelector(getBookingRooms);
  let diff: number;
  if (search) {
    diff = differenceInDays(search?.to, search?.from);
  }

  let dynamicTitle = 'N/A';

  const location = useLocation();
  switch (location.pathname) {
    case CONSTANTS.APP_ROUTES.PROPERTIES:
      dynamicTitle = 'SELECT PROPERTY';
      break;
    case CONSTANTS.APP_ROUTES.ROOMS:
      dynamicTitle = 'SELECT ROOMS';
      break;
    case CONSTANTS.APP_ROUTES.RATES:
      dynamicTitle = 'SELECT ADDONS';
      break;
    case CONSTANTS.APP_ROUTES.SUMMARY:
      dynamicTitle = 'ALMOST THERE';
      break;
    case CONSTANTS.APP_ROUTES.DETAILS:
      dynamicTitle = 'YOUR BOOKING';
      break;
    case CONSTANTS.APP_ROUTES.SUCCESS:
      dynamicTitle = 'SUCCESS!';
      break;
    default:
      dynamicTitle = ''; //'WELCOME TO THE WHOLE NEW EXPERIENCE OF HOLIDAYS.';
      break;
  }

  const renderSearch = () => (
    <div className="ibe-side-bar__search">
      <div className="ibe-side-bar__search__header">
        <h1>{dynamicTitle}</h1>
        <div className="ibe-side-bar__search__header__hr" />
      </div>
      <b>{search && format(search.from, 'do LLLL')}</b> to{' '}
      <b>{search && format(search.to, 'do LLLL y')}</b>
      <br />
      <b>{search && search.adults} Adults</b> for <b>{diff} Nights</b>
      {property && (
        <div>
          Staying at <b>{property?.name}</b>
        </div>
      )}
      {rooms?.map((bRoom, i) => (
        <div key={bRoom.id}>
          <div className="ibe-side-bar__search__room__row">
            <div className="ibe-side-bar__search__room__index">
              Room {i + 1}
            </div>
            <div className="ibe-side-bar__search__room__line-hr" />
          </div>
          <div>
            <div>
              <b>{bRoom.room.maxOccupancy} Adults</b> Sleeping in a{' '}
              {bRoom.room.name} - {bRoom.rate.name} Rate{' '}
              <b>
                {bRoom.rate.currency}{' '}
                {bRoom.rate.net / bRoom.rate.nights.length}/Night
              </b>
            </div>
          </div>
          {bRoom.addons.length > 0 && (
            <div>
              <div className="ibe-side-bar__search__room__addon__title">
                Add-Ons
              </div>
              {bRoom.addons.map((a) => (
                <div
                  key={a.id}
                  className="ibe-side-bar__search__room__addon__description">
                  <b>{a.name}</b>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
      {rooms && rooms.length > 0 && (
        <div className="ibe-side-bar__search__room__separator" />
      )}
    </div>
  );

  return (
    <div className="ibe-side-bar">
      {!search && (
        <div className="ibe-side-bar__placeholder">{dynamicTitle}</div>
      )}
      {search && renderSearch()}
      <div className="ibe-side-bar__vr d-none">
        <div className="ibe-side-bar__right-asset" />
      </div>
    </div>
  );
};

export default SideBar;
