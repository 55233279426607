import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import {useHistory} from 'react-router-dom';

import '../styles/Properties.scss';
import Button from '../components/Button';
import {getProperties} from '../selectors/Property';
import CONSTANTS from '../helpers/constants';
import {bookingPropertyClear, bookingSetProperty} from '../actions/Booking';
import locationSvg from '../assets/location.svg';
import {Property} from '../types/Property';
import {getRates} from '../selectors/Rate';

const Properties = () => {
  const properties = useSelector(getProperties);
  const rates = useSelector(getRates);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(bookingPropertyClear());
  }, [dispatch]);

  const onSelectProperty = (property: Property) => {
    if (hasAvailability(property)) {
      dispatch(bookingSetProperty(property));
      history.push(CONSTANTS.APP_ROUTES.ROOMS);
    }
  };

  const hasAvailability = (property: Property) =>
    rates?.find((r) => r.propertyCode === property.propertyCode);

  return (
    <div className="ibe-properties">
      <div className="row g-0">
        {properties.map((property) => (
          <div
            className="col-lg-6 cursor-pointer"
            key={property.propertyCode}
            onClick={() => onSelectProperty(property)}>
            <div
              style={{backgroundImage: `url('${_.first(property.images)}')`}}
              className="ibe-properties__property m-2">
              <div className="ibe-properties__property__details">
                <div className="ibe-properties__property__hr" />
                <div className="row g-0">
                  <div className="col-8">
                    <div className="ibe-properties__property__details__name">
                      {property.name}
                    </div>
                    <div className="flex-row d-flex align-items-center">
                      <div className="pe-2">
                        <img src={locationSvg} alt="location-icon" />
                      </div>
                      <div className="ibe-properties__property__details__city-country">
                        {property.city}
                        <br />
                        {property.countryCode}
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="ibe-properties__property__details__from">
                      FROM
                    </div>
                    <div className="ibe-properties__property__details__price-point">
                      {property.currencyCode} <b>123</b>
                      <span>/NIGHT</span>
                    </div>
                    {hasAvailability(property) && (
                      <Button text="SELECT" type="red" />
                    )}
                    {!hasAvailability(property) && (
                      <Button text="SOLD OUT" type="red-disabled" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Properties;
