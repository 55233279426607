import {ThunkAction} from 'redux-thunk';
import {Action} from 'redux';
import {fetchPropertyRates} from '../api/RateApi';
import {RootState} from '../store/configureStore';
import {
  Rate,
  RateActionTypes,
  RATES_FETCH_FAILURE,
  RATES_FETCH_REQUEST,
  RATES_FETCH_RESPONSE,
} from '../types/Rate';
import {bookingSearchSet} from './Booking';
import {Search} from '../types/Booking';

function ratesGetRequest(): RateActionTypes {
  return {
    type: RATES_FETCH_REQUEST,
    payload: null,
  };
}

function ratesGetResponse(rates: Rate[]): RateActionTypes {
  return {
    type: RATES_FETCH_RESPONSE,
    payload: rates,
  };
}

function ratesGetFailure(): RateActionTypes {
  return {
    type: RATES_FETCH_FAILURE,
    payload: null,
  };
}

export const ratesGet =
  (search: Search): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(ratesGetRequest());
    const asyncResp: any = await fetchPropertyRates(
      search.from,
      search.to,
      search.adults,
      search.children,
    );
    if (asyncResp.success) {
      dispatch(bookingSearchSet(search));
      const rates: Rate[] = [];
      asyncResp.data?.forEach((hotel: any) =>
        hotel?.rooms.forEach((room: any) =>
          room.rates.forEach((rate: Rate) =>
            rates.push({
              ...rate,
              roomCode: room.code,
              propertyCode: hotel?.code,
            }),
          ),
        ),
      );
      dispatch(ratesGetResponse(rates));
    } else {
      dispatch(ratesGetFailure());
    }
  };
