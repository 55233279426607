import {Rate} from './Rate';
import {Property} from './Property';
import {Room} from './Room';
import {Addon} from './Addon';

export const BOOKING_SET_PROPERTY = 'BOOKING_SET_PROPERTY';
export const BOOKING_CLEAR_PROPERTY = 'BOOKING_CLEAR_PROPERTY';
export const BOOKING_SET_SEARCH = 'BOOKING_SET_SEARCH';
export const BOOKING_CLEAR_SEARCH = 'BOOKING_CLEAR_SEARCH';
export const BOOKING_ADD_ROOM = 'BOOKING_ADD_ROOM';
export const BOOKING_EDIT_ROOM = 'BOOKING_EDIT_ROOM';
export const BOOKING_CLEAR_ROOM = 'BOOKING_CLEAR_ROOM';
export const BOOKING_CLEAR_ROOMS_AND_RATES = 'BOOKING_CLEAR_ROOMS_AND_RATES';
export const BOOKING_CLEAR_ADDONS = 'BOOKING_CLEAR_ADDONS';

export interface CreditCardDetails {
  name: string;
  creditCardNumber: number;
  expiryDate: string;
  cvv: number;
}

export interface Search {
  from: Date;
  to: Date;
  adults: number;
  children: number;
  roomsQuantity: number;
  propertyCode?: string;
  promo: string;
}

export interface UserDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  nationality: string;
  terms: boolean;
  community: boolean;
  payment: CreditCardDetails;
}

export interface BookingRoom {
  room: Room;
  rate: Rate;
  addons: Addon[];
  id: string;
}

export interface BookingState {
  property?: Property | null;
  rooms?: BookingRoom[] | null;
  details?: UserDetails | null;
  search: Search | null;
}

interface BookingSetPropertyAction {
  type: typeof BOOKING_SET_PROPERTY;
  payload: Property;
}

interface BookingClearPropertyAction {
  type: typeof BOOKING_CLEAR_PROPERTY;
  payload: null;
}

interface BookingClearRoomsAndRatesAction {
  type: typeof BOOKING_CLEAR_ROOMS_AND_RATES;
  payload: null;
}

interface BookingClearAddonsAction {
  type: typeof BOOKING_CLEAR_ADDONS;
  payload: null;
}

interface BookingEditRoomAction {
  type: typeof BOOKING_EDIT_ROOM;
  payload: {
    id: string;
    room: BookingRoom;
  };
}

interface BookingAddRoomAction {
  type: typeof BOOKING_ADD_ROOM;
  payload: BookingRoom;
}

interface BookingClearRoomAction {
  type: typeof BOOKING_CLEAR_ROOM;
  payload: {
    id: string;
  };
}

interface BookingClearPropertyAction {
  type: typeof BOOKING_CLEAR_PROPERTY;
  payload: null;
}

interface BookingSetSearchAction {
  type: typeof BOOKING_SET_SEARCH;
  payload: Search;
}

interface BookingClearSearchAction {
  type: typeof BOOKING_CLEAR_SEARCH;
  payload: null;
}

export type BookingActionTypes =
  | BookingSetPropertyAction
  | BookingClearPropertyAction
  | BookingSetSearchAction
  | BookingClearSearchAction
  | BookingEditRoomAction
  | BookingClearRoomAction
  | BookingAddRoomAction
  | BookingClearAddonsAction
  | BookingClearRoomsAndRatesAction;
