import axios from 'axios';
import formatDate from 'date-fns/format';

// import GetAvailJson from '../mock/GetAvail.json';
import c from '../helpers/constants';

export function fetchPropertyRates(
  from: Date,
  to: Date,
  adults: number,
  children: number,
) {
  const fromFormatted = formatDate(from, 'Y-m-d');
  const toFormatted = formatDate(to, 'Y-m-d');
  return (
    axios({
      url: `${c.API_URL}?
    action=${c.API_ACTIONS.AVAILABILITY}&
    chain=${c.CHAIN}&
    from=${fromFormatted}&
    to=${toFormatted}&
    adults=${adults}&
    children=${children}&
    hotels=${c.HOTEL_CODES.join(',')}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response: any) => response?.data)
      // .then(() => GetAvailJson)
      .catch((error: any) => {
        console.error(error);
      })
  );
}
