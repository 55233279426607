export const ADDONS_FETCH_REQUEST = 'ADDONS_FETCH_REQUEST';
export const ADDONS_FETCH_RESPONSE = 'ADDONS_FETCH_RESPONSE';
export const ADDONS_FETCH_FAILURE = 'ADDONS_FETCH_FAILURE';
export const ADDONS_CLEAR = 'ADDONS_CLEAR';

export interface Addon {
  id: string;
  code: number;
  quantity: number;
  name: string;
  pricingType: 'Per Item' | 'Per Day' | 'Per Guest' | 'Per Stay';
  description: string;
  category: string;
  scheduled: string;
  preference: string;
  cancelPolicy: string;
  amountNet: number;
  amountTotal: number;
  roomCode: string;
  rateCode: string;
  propertyCode: string;
  bookingRoomId: string;
}

export interface AddonState {
  addons: Addon[];
  isFetchingAddons: boolean;
  fetchingAddonsFailed: boolean;
}

interface AddonsGetRequestAction {
  type: typeof ADDONS_FETCH_REQUEST;
  payload: null;
}

interface AddonsClearAction {
  type: typeof ADDONS_CLEAR;
  payload: null;
}

interface AddonsGetResponseAction {
  type: typeof ADDONS_FETCH_RESPONSE;
  payload: Addon[];
}

interface AddonsGetFailureAction {
  type: typeof ADDONS_FETCH_FAILURE;
  payload: null;
}

export type AddonsActionTypes =
  | AddonsGetRequestAction
  | AddonsGetResponseAction
  | AddonsGetFailureAction
  | AddonsClearAction;
