import React from 'react';
import './Amenity.scss';
import {Amenity} from '../../types/Room';

type AmenityProps = {
  amenities: Amenity[];
};

const AmenityComponent = (props: AmenityProps) => {
  const {amenities = [{name: 'N/A', code: 'amenity'}]} = props;

  return (
    <div className="ibe-amenities">
      {amenities.map((a, i) => (
        <div key={i} className="ibe-amenities__item">
          <img
            className="ibe-amenities__item__img"
            src={'/amenity.svg'}
            alt={a.code}
          />
          <div className="ibe-amenities__item__title">{a.name}</div>
        </div>
      ))}
    </div>
  );
};

export default AmenityComponent;
