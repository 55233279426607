import {createStore, applyMiddleware} from 'redux';
import thunk, {ThunkAction} from 'redux-thunk';
import {Action, combineReducers} from 'redux';
import propertyReducer from '../reducers/Property';
import rateReducer from '../reducers/Rate';
import addonReducer from '../reducers/Addon';
import bookingReducer from '../reducers/Booking';

const rootReducer = combineReducers({
  property: propertyReducer,
  rate: rateReducer,
  addon: addonReducer,
  booking: bookingReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer, applyMiddleware(thunk));

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
