import React from 'react';

import './App.scss';
import Router from '../Router';
import NavBar from '../NavBar';
import SideBar from '../SideBar';

function App() {
  return (
    <div className="ibe-container">
      <div className="container-fluid">
        <div className="row">
          <div className="ibe-container__inner col-lg-10 col-xs-12 offset-lg-1">
            <div className="ibe-container__left-asset" />
            <div className="container-fluid g-0">
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <NavBar />
                  <div className="booking-container row g-0">
                    <div className="col-md-12">
                      <Router />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-xs-12">
            <SideBar />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
