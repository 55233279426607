import {ThunkAction} from 'redux-thunk';
import {Action} from 'redux';
import {v4 as uuidv4} from 'uuid';

import {Property} from '../types/Property';
import {RootState} from '../store/configureStore';
import {
  BOOKING_ADD_ROOM,
  BOOKING_CLEAR_ADDONS,
  BOOKING_CLEAR_PROPERTY,
  BOOKING_CLEAR_ROOM,
  BOOKING_CLEAR_ROOMS_AND_RATES,
  BOOKING_CLEAR_SEARCH,
  BOOKING_EDIT_ROOM,
  BOOKING_SET_PROPERTY,
  BOOKING_SET_SEARCH,
  BookingActionTypes,
  BookingRoom,
  Search,
} from '../types/Booking';
import {Rate} from '../types/Rate';
import {Room} from '../types/Room';

export function bookingAddRoom(room: BookingRoom): BookingActionTypes {
  return {
    type: BOOKING_ADD_ROOM,
    payload: room,
  };
}

export function bookingEditRoom(
  id: string,
  room: BookingRoom,
): BookingActionTypes {
  return {
    type: BOOKING_EDIT_ROOM,
    payload: {
      id,
      room,
    },
  };
}

export function bookingClearRoom(id: string): BookingActionTypes {
  return {
    type: BOOKING_CLEAR_ROOM,
    payload: {
      id,
    },
  };
}

export function bookingSetProperty(property: Property): BookingActionTypes {
  return {
    type: BOOKING_SET_PROPERTY,
    payload: property,
  };
}

export function bookingClearProperty(): BookingActionTypes {
  return {
    type: BOOKING_CLEAR_PROPERTY,
    payload: null,
  };
}

export function bookingSetSearch(search: Search): BookingActionTypes {
  return {
    type: BOOKING_SET_SEARCH,
    payload: search,
  };
}

export function bookingClearSearch(): BookingActionTypes {
  return {
    type: BOOKING_CLEAR_SEARCH,
    payload: null,
  };
}

export function bookingClearRoomsAndRates(): BookingActionTypes {
  return {
    type: BOOKING_CLEAR_ROOMS_AND_RATES,
    payload: null,
  };
}

export function bookingClearAddons(): BookingActionTypes {
  return {
    type: BOOKING_CLEAR_ADDONS,
    payload: null,
  };
}

export const bookingAddonsClear =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(bookingClearAddons());
  };

export const bookingRoomsAndRatesClear =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(bookingClearRoomsAndRates());
  };

export const bookingRoomAdd =
  (
    room: Room,
    rate: Rate,
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(bookingAddRoom({rate, room, id: uuidv4(), addons: []}));
  };

export const bookingRoomEdit =
  (
    id: string,
    room: BookingRoom,
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(bookingEditRoom(id, room));
  };

export const bookingRoomClear =
  (id: string): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(bookingClearRoom(id));
  };

export const bookingSearchSet =
  (search: Search): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(bookingSetSearch(search));
  };

export const bookingSearchClear =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(bookingClearSearch());
  };

export const bookingPropertySet =
  (property: Property): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(bookingSetProperty(property));
  };

export const bookingPropertyClear =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(bookingClearProperty());
  };
